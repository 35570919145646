import { Component, h } from 'preact';
import { Link } from 'preact-router';
import { useTranslation } from 'react-i18next';
import contact from './contact';

class ContactBanner extends Component {

	render() {
		const {props} = this;
		const content = props.object
        const { t } = useTranslation();

		return (
            <div className={`contactBanner_block ` + content.style}>
				<div className='container'>
					<div className={`contactBanner_block--content`}>
						<h6>{t('Contact us')}</h6>
						<p>{t('Do you have any question, write to us!')}</p>
						{(content?.page?.url) ? (
							<div className='button-container'>
								<Link href='/contact' type='button' class="btn btn-primary">{t('Contact')}</Link>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

export default ContactBanner;
