import { Link, Router } from 'preact-router';

// Code-splitting is automated for `routes` directory
import ApiProvider, { ApiProviderContext } from './ApiProvider';
import { useContext } from 'preact/hooks';
import Page from './Page';
import Error from './Error';
import FatalError from './FatalError';
import CookieConsent, { Cookies } from "react-cookie-consent";

import { Trans, useTranslation } from 'react-i18next';

const App = () => {
	const { t } = useTranslation();

	return (
		<ApiProvider>
			<div id="app">
				<Router onChange={(route) => {
					const { apiProvider } = useContext(ApiProviderContext)
					apiProvider.routeDidUpdate(route)
				}}>
					<FatalError path="/fatal-error" />
					<Error path="/error" />
					<Page default/>
				</Router>

				<CookieConsent
					location="bottom"
					buttonText={t('Accept')}
					cookieName="teamwant_eu_cookie"
					style={{ background: "#2B373B" }}
					buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
					expires={150}
					>
					<Trans
						i18nKey="cookie_info" 
						components={{ privacy_policy: <Link href="/privacy-policy" /> }}
					/>
				</CookieConsent>

			</div>
		</ApiProvider>
	)
}

export default App;
