import Cookies from "js-cookie";
import { Component } from "preact";
import { useContext } from "preact/hooks";
import i18n from "../i18n";
import '../style/lang.css'
import { ApiProviderContext, useApiProviderHelper } from "./ApiProvider";

const languages = [
    { code: 'en', name: 'English' },
    { code: 'pl', name: 'Polski' },
    { code: 'fr', name: 'France' },
    { code: 'de', name: 'Deutsch' },
    { code: 'it', name: 'Italy' }
]

export class LanguageSwitcher extends Component {
    
    state = {
        lang: 'en'
    };

    changeLanguageHandler = (lang) => {
        this.setState({ lang: lang });
        Cookies.set('language', lang)
        i18n.changeLanguage(lang);
    }

    componentDidMount = () => {
        let t = this;
        let cookieLang = Cookies.get('language');
        let stop = 0;

        if (cookieLang) {

            if (cookieLang === this.state.lang) {
                stop = 1;
                return;
            }

            languages.forEach(function(a, b) {
                if (a.code === cookieLang) {
                    t.setState({ lang: cookieLang })
                    i18n.changeLanguage(cookieLang);
                    stop = 1;
                    return;
                }
            })

            if (stop) {
                return;
            }
        }
        
        
        let lang = window.navigator.languages ? window.navigator.languages[0] : null;
        lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

        let shortLang = lang;
        if (shortLang.indexOf('-') !== -1)
            shortLang = shortLang.split('-')[0];

        if (shortLang.indexOf('_') !== -1)
            shortLang = shortLang.split('_')[0];


        if (shortLang === this.state.lang) {
            i18n.changeLanguage(this.state.lang);
            return;
        }


        languages.forEach(function(a, b) {
            if (a.code === shortLang) {
                t.setState({ lang: shortLang })
                i18n.changeLanguage(shortLang);
                return;
            }
        })
    }

    render() {
        return (
            <div className="languageSwitcher">
                <LanguageSwitcherSelector url={this.props?.url}
                    lang={this.state.lang}
                    handleChangeLanguage={this.changeLanguageHandler}
                />
            </div>
        )
    }
}


export class LanguageSwitcherSelector extends Component {
    onChange = (e, apiProvider) => {
        this.props.handleChangeLanguage(e.target.className);

        if (this.props?.url) {
            apiProvider.refresh(this.props.url)
        }
    }

    render() {
        const { apiProvider } = useContext(ApiProviderContext)

        const options = languages.map(language => {
            if (language.code != this.props.lang) {
                return <li onClick={(e) => {this.onChange(e, apiProvider)}}><div value={language.code} className={language.code} ></div></li>
            }
        });

        return (
            <div className="lang">
                <div
                    className={this.props.lang}
                >
                </div>
                <ul class="dropdown" >
                    {options}
                </ul>
            </div>
        )
    }
}
