import axios from 'axios';
import { Component, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import { ApiProviderContext } from './ApiProvider';
import Header from './apiComponents/header';
import DarkHeader from './apiComponents/DarkHeader';
import DarkFooter from './apiComponents/DarkFooter';
import { LanguageSwitcher } from './Language';

const Loading = () =>
    <div className='loading-container'>
        <div className="loading">
            <div></div>
            <div></div>
        </div>
    </div>

class Error extends Component {
    constructor() {
        super();
    }

    state = {
        loading: true,
    };

    render() {
        const { content } = useContext(ApiProviderContext)
        
        if (content.data) {
            return (
                <Fragment>
                    <div className='dark_style'>
                        <DarkHeader page={this.props?.page} object={this.props?.object} url={this.props.url}></DarkHeader>
                        <div className='success-container'>
                            <p className='title'>Unexpected error</p>
                        </div>
                        <DarkFooter page={this.props?.page} object={this.props?.object} url={this.props.url}></DarkFooter>
                    </div>
                </Fragment>
            );
        } else {
            return <Loading />
        }
    }
}

export default Error;
