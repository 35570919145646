import axios from "axios";
import Cookies from "js-cookie";
import { route } from "preact-router";
import { BACKEND_DOMAIN } from "../config";

const domain = BACKEND_DOMAIN;
export const connectionInformation = {
    "url": domain + '/api/frontend-configuration',
    "url_login": domain + '/api/auth/local',
    "url_register": domain + '/api/auth/local/register',
    "url_confirm": domain + '/api/auth/email-confirmation',
    "url_user_websites": domain + '/api/users-permissions/user/websites',
    "contact_create": domain + '/api/contact-request/contact/create',
}

export async function getPage(routeUrl) {
    let url = connectionInformation.url + '?url=' + routeUrl

    let status = 0;
    let response = {};

    let lang = Cookies.get('language')
    if (lang) {
        url += '&locale=' + lang
    }

    let config = {
        headers: {}
    };

    if (localStorage.jwt) {
        config.headers['Authorization'] = `Bearer ${localStorage.jwt}`
    }
    
    // return axios.get(url).then(response => response.data);

    await axios.get(url, config)
        .then(function (r) {
            status = r.status;
            response = r.data;
        })
        .catch(function (error) {
            status = error.response.status;
            response = error.response.data;
        })
        .then(function () {
            // always executed
        })
    ;

    return {
        'status': status,
        'response': response,
    }
}

export async function login(email, password) {
    let url = connectionInformation.url_login
    let data = new FormData();

    let status = 0;
    let response = {};

    data.append('identifier', email);
    data.append('password', password);

    await axios.post(url, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
      })
        .then(function (r) {
            status = r.status;
            response = r.data;
        })
        .catch(function (error) {
            status = error.response.status;
            response = error.response.data;
        })
        .then(function () {
            // always executed
        })
    ;


    if (response?.user) {
        localStorage.setItem("user", response.user);
        localStorage.setItem("jwt", response.jwt);
        route('/panel', true);
        return;
    }

    return {
        'status': status,
        'response': response,
        'error': response?.error?.message,
        'errors': response?.error?.details?.errors,
    }
}

export async function register(email, system, hcaptcha_token, hcaptcha_ekey) {
    let url = connectionInformation.url_register
    let data = new FormData();

    let status = 0;
    let response = {};

    data.append('email', email);
    data.append('system', system);
    data.append('hcaptcha_token', hcaptcha_token);
    data.append('hcaptcha_ekey', hcaptcha_ekey);

    await axios.post(url, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
        .then(function (r) {
            status = r.status;
            response = r.data;
        })
        .catch(function (error) {
            status = error.response.status;
            response = error.response.data;
        })
        .then(function () {
            // always executed
        })
    ;

    if (response?.user) {
        route('/success-register', true);
        return;
    }

    //wymuszenie errors gdy jest error
    if (response?.error?.message && !response?.error?.details?.errors) {
        response.error.details.errors = [{message: response?.error?.message}];
    }

    return {
        'status': status,
        'response': response,
        'error': response?.error?.message,
        'errors': response?.error?.details?.errors,
    }
}

export async function contactRequest(contactState) {
    let url = connectionInformation.contact_create
    let data = new FormData();

    let status = 0;
    let response = {};

    data.append('email', contactState.state.email);
    data.append('firstname', contactState.state.firstname);
    data.append('type', contactState.state.type);
    data.append('description', contactState.state.description);
    data.append('hcaptcha_token', contactState.state.hcaptcha_token);
    data.append('hcaptcha_ekey', contactState.state.hcaptcha_ekey);

    await axios.post(url, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
      })
        .then(function (r) {
            status = r.status;
            response = r.data;
        })
        .catch(function (error) {
            status = error.response.status;
            response = error.response.data;
        })
        .then(function () {
            // always executed
        })
    ;

    return {
        'status': status,
        'response': response,
        'error': response?.error?.message,
        'errors': response?.error?.details?.errors,
    }
}

export async function logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
    route('/', true);
}

/**
 * 
 * @param {EmailConfirmation} apiComponents 
 * @returns 
 */
export async function emailConfirmation(apiComponents) {
    // https://sandbox.frontend.teamwant.eu/api/auth/email-confirmation?confirmation=192cbba7c4d1301297835c9c97c8c76bdd9287c3
    let url = connectionInformation.url_confirm

    let status = 0;
    let response = {};

    if (!apiComponents?.props?.url || !window.location.href) {
        apiComponents.setState({
			has_response: 1,
			error: 'url is required',
		});

        return;
    }

    try {
        const full_url = new URL(window.location.href); // todo przerobic na props
        const queryParams = new URLSearchParams(full_url.search);

        if (!queryParams.get('confirmation')) {
            apiComponents.setState({
                has_response: 1,
                error: 'Confirmation code not found',
            });
    
            return;
        }

        await axios.get(url, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: queryParams
            })
            .then(function (r) {
                status = r.status;
                response = r.data;
            })
            .catch(function (error) {
                status = error.response.status;
                response = error.response.data;
            })
            .then(function () {
                // always executed
            })
        ;
    
    } catch (error) {
        apiComponents.setState({
			has_response: 1,
			error: 'URL parse error',
		});

        return;
    }

    if (status == 200 || status == 204) {
        apiComponents.setState({
			has_response: 1,
			error: null,
		});

        return;
    }

    apiComponents.setState({
        has_response: 1,
        error: response?.error?.message,
    });

    return;
}


/**
 * 
 * @param {Panel} apiComponents 
 * @returns 
 */
 export async function getUserWebsites(apiComponents) {
    let url = connectionInformation.url_user_websites

    let status = 0;
    let response = {};

    if (!apiComponents?.props?.url || !window.location.href) {
        apiComponents.setState({
			has_response: 1,
			error: 'url is required',
		});

        return;
    }

    try {
        const full_url = new URL(window.location.href); // todo przerobic na props
        const queryParams = new URLSearchParams(full_url.search);

        let config = {
            headers: {"Content-Type": "multipart/form-data"}
        };

        if (localStorage.jwt) {
            config.headers['Authorization'] = `Bearer ${localStorage.jwt}`
        }

        if (queryParams) {
            config.params = queryParams
        }

        await axios.get(url, config)
            .then(function (r) {
                status = r.status;
                response = r.data;
            })
            .catch(function (error) {
                status = error.response.status;
                response = error.response.data;
            })
            .then(function () {
                // always executed
            })
        ;
    
    } catch (error) {
        apiComponents.setState({
			has_response: 1,
			error: 'URL parse error',
		});

        return;
    }

    if (status == 200 || status == 204) {
        apiComponents.setState({
			has_response: 1,
			error: null,
            websites: response,
		});

        return;
    }

    apiComponents.setState({
        has_response: 1,
        error: response?.error?.message,
    });

    return;
}