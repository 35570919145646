import { t } from 'i18next';
import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { emailConfirmation } from '../../api';
import { useTranslation } from 'react-i18next';

// import style from './style.css';

class EmailConfirmation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			has_response: 0,
			error: null,
		};
		


		setTimeout(() => {
			emailConfirmation(
				this
			);
		}, 100);
	}

	render() {
		const {props} = this;
		const content = props.object
		const { t } = useTranslation();
		return (
            <div className='container email_confirmation_block'>
				{(this.state.has_response) ? this.displayReturn() : (
					<div className='loading-container'>
						<div className="loading">
							<div></div>
							<div></div>
						</div>  
					</div>
				)}

			</div>
		);
	}

	displayReturn () {
		if (this.state.error) {
			return (
				<div className='success-container'>
					<p className='title'>{t("Your account hasn't been confirmed")}</p>
					<p className='desc'>{this.state.error}</p>
					<p className='desc'>{t("If you'r not seeing the mail check spam")}</p>
					<div className='success-buttons'>
						<a type="button" class="btn btn-primary button-login" href='/'>{t('Homepage')}</a>
						<a type="button" class="btn btn-primary button-contact" href='/new-site'>{t('Register Page')}</a>
					</div>
				</div>
			)
		} else {
			return (
				<div className='success-container'>
					<p className='title'>{t('Your account has been confirmed')}</p>
					<p className='desc'>{t('Now you can access your account trough panel page')}</p>
					<div className='success-buttons'>
						<a type="button" class="btn btn-primary button-login" href='/'>{t('Homepage')}</a>
						<a type="button" class="btn btn-primary button-contact" href='/panel'>{t('Panel Page')}</a>
					</div>
				</div>
			)
		}
	}
}

export default EmailConfirmation;
