import { Component, h } from 'preact';
import ReactMarkdown from 'react-markdown'

class cms extends Component {

	render() {
		const {props} = this;
		const content = props.object
		return (
            <div className={`cms_block ` + (content.has_fluid ? 'container-fluid fluidcms' : 'container')}>
				<div className={`cms_block--content`}>
					<h1>{content.Title}</h1>
					<hr />
					<ReactMarkdown children={content.LongText} />
				</div>
			</div>
		);
	}
}

export default cms;
