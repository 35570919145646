import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { useTranslation } from 'react-i18next';
// import style from './style.css';

class DarkFooter extends Component {

	render() {
		const {props} = this;
		const content = props.object
		const { t } = useTranslation();
		return (
            <div className='container-fluid'>
				<div className='login-footer'>
					<div className='footer-date'>
						<p>© 2022</p>
					</div>
					<div className='footer-privacy'>
						<Link href='/privacy-policy' className='footer-link'>{t('Privacy Policy')}</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default DarkFooter;
