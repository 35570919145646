import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { logout } from '../../api';
import { BACKEND_DOMAIN } from "../../../config";
import { getUserWebsites } from '../../api';
import { useTranslation } from 'react-i18next';
// import style from './style.css';

class Panel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			has_response: 0,
			error: null,
			websites: null,
		};


		setTimeout(() => {
			getUserWebsites(
				this
			);
		}, 100);
	}

	handleLogout(event) {
		event.preventDefault();
		logout();
	}

	render() {
		const {props} = this;
		const content = props.object
		return (
            <div className='panel-page'>
				<div className='panel-navigation'>
					<div className='navigation-image'>
						<Link href='/'>
							<picture>
								<source srcset="/assets/images/logo.webp" type="image/webp"></source>
								<source srcset="/assets/images/logo.png" type="image/png"></source>
								<img src="/assets/images/logo.png" alt="logo"></img>
							</picture>
						</Link>
					</div>
					<div className='navigation-menu'>
						{(content.MenuItem) ? this.generateMenu(content.MenuItem) : null}
					</div>
					<div className='navigation-logout'>
						<a className='logout-item' onClick={this.handleLogout}>
							<img alt='navbar-icon4' src='/assets/images/Vector.svg'></img>
							<h3>{content.NavbarLogout}</h3>
						</a>
					</div>
				</div>
				<div className='panel-content'>
					{(this.state.has_response) ? this.displayServices() : (
						<div className='loading-container'>
							<div className="loading">
								<div></div>
								<div></div>
							</div>  
						</div>
					)}
				</div>
			</div>
		);
	}

	generateMenu(MenuItem) {
		return MenuItem.map((object, i) => {
			try {
				return (
					<Link className='menu-item' href={object.page.url}>

						{(object.icon && object.icon[0]?.url) ? (
							<img alt='navbar_icon2' src={BACKEND_DOMAIN + object?.icon[0]?.url} />
						) : null}

						<h3>{object.Title}</h3>
					</Link>
				)
			} catch (error) {
				return null
			}
		})
	}

	displayServices () {
        const { t } = useTranslation();
		if (this.state.error) {
			return (
				<div class="alert alert-warning" role="alert">
					{this.state.error}
				</div>
			)
		} else {
			if (!this.state.websites.length) {
				return (
					<div class="alert alert-warning" role="alert">
						Any websites not found
					</div>
				)
			}
			
			return this.state.websites.map((object, i) => {
				if (!/^http?:\/\//i.test(object.domain)) {
					object.domain = 'http://' + object.domain;
					object.admin_url = 'http://' + object.admin_url;
				}

				try {
					return (
						<div className={object.is_premium ? 'content-block premium' : 'content-block'}>
							<div className='block-image'>
								{(object.system == 'woocommerce') ? (
									<img alt='presta_logo' src='/assets/images/WordPress_logo.svg'></img>
								) : null}
								{(object.system == 'prestashop 1.7') ? (
									<img alt='presta_logo' src='/assets/images/panel_web_logo.svg'></img>
								) : null}
								{(object.system == 'opencart') ? (
									<img alt='presta_logo' src='/assets/images/opencart.svg'></img>
								) : null}
								{(object.system == 'shopware 6') ? (
									<img alt='presta_logo' src='/assets/images/shopware-logo.svg'></img>
								) : null}
							</div>
							<div className='block-info'>
								
								<h4>{t('Login information')}</h4>
								<p>{t('Domain')}: <Link>{object.domain}</Link></p>
								<p>{t('Admin Panel')}: <Link>{object.admin_url}</Link></p>
								<p>{t('Login')}: {object.login}</p>
								<p>{t('Password')}: {object.password}</p>
							</div>

							{(object.is_premium) ? (
								<div className='block-info premium'>
									<h4>{t('Connection information')}:</h4>
									<p>{t('FTP Login')}: {object.ftp_login}</p>
									<p>{t('FTP Password')}: {object.ftp_password}</p>
									<p>{t('FTP server')}: {object.ftp_server}</p>
									<p>{t('FTP port')}: {object.ftp_port}</p>
								</div>
							) : (
								<div className='block-info premium'></div>
							)}

							<div className='block-buttons'>
								{/* <button type="button" class="btn btn-primary button-open">{object.Button1}</button>
								<button type="button" class="btn btn-primary button-contact">{object.Button2}</button> */}

								<a className='btn btn-primary button-open' href={object.domain} target='_blank'>
									{t('Open')}
								</a>

								<Link className='btn btn-primary button-contact' href={'/contact'} >
									{t('Contact')}
								</Link>

							</div>
						</div>
					)
				} catch (error) {
					return null
				}
			})
		}
	}
}

export default Panel;
