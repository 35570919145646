import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { HCAPTCHA_SITE_KEY } from '../../../config';
import { contactRequest } from '../../api';
import { useTranslation } from 'react-i18next';

// import style from './style.css';

class contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			firstname: '',
			description: '',
			type: '',
			hcaptcha_token: '',
			hcaptcha_ekey: '',
			error: '',
			errors: [],
			isSubmited: false,
			isSuccess: false,

            avaliable_types: [
                'Support',
				'Store transfer',
				'Creating a new store',
            ],
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleVerificationSuccess(token, ekey) {
		this.setState({
			hcaptcha_token: token,
			hcaptcha_ekey: ekey,
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({'isSubmited': true})

		let returnData = await contactRequest(this);

		this.setState({'isSubmited': false})

		if (returnData?.error) {
			this.setState({
				'error': returnData.error,
				'errors': returnData.errors,
			})
		} else {
			this.setState({'isSuccess': true})
		}
	}

	render() {
		const {props} = this;
		const content = props.object
		const { t } = useTranslation();

		return (
            <div className='container-fluid contact-bg'>
				<div className='tw-contact'>
					<div className='contact-left'>
						<p className='section-title'>{content.ContactInformation}</p>
						<p className='phone'>{t('Phone')}: {content.Phone}</p>
						<p className='email'>{t('Email')}: {content.Email}</p>
						<p className='address'>{t('Address')}: {content.Addres}</p>
					</div>
					<div className='contact-right'>
						<p className='section-title'>{content.ContactUs}</p>
						{this._renderForm()}
					</div>
				</div>
			</div>
		);
	}

	_renderForm() {

		const {props} = this;
		const content = props.object
		const { t } = useTranslation();

		if (this.state.isSuccess) {
			return (
				<div class="alert alert-success success" role="alert">
					{t("Your mail has been sent we will respond to your email in 48 hours")}
				</div>
			)
		}

		if (this.state.isSubmited) {
			return (
				<div className='relative-container'>
					<div className='loading-container'>
						<div className="loading">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<form onSubmit={this.handleSubmit}>

				{(this.state.errors && this.state.errors.length ? (
					(this.state.errors.map((item) => {
						return <div class="alert alert-warning" role="alert">
							{item.message}
						</div>
					}))
				) : null)}

				<div class="form-container">
					<div class="inputs-container">
						<label for="uname">{content.InputEmail}</label>
						<input name="email" type="text" value={this.state.email} onInput={this.handleChange} required />

						<label for="uname">{content.InputUrl}</label>
						<input name="firstname" type="text" value={this.state.firstname} onInput={this.handleChange} required />

						<label for="uname"><b>{t('Subject')}</b></label>
						<select name="type" value={this.state.type} onInput={this.handleChange} required >
							{this.state.avaliable_types.map((value, index) => <option value={value} selected={(index == 1) ? true : false}>{value}</option>)}
						</select>

						<label for="uname">{content.InputMessage}</label>
						<textarea name="description" type="text" value={this.state.description} onInput={this.handleChange} required />


					</div>
					<div class="inputs-container captcha-container">
						<HCaptcha
							sitekey={HCAPTCHA_SITE_KEY}
							onVerify={(token, ekey) => {this.handleVerificationSuccess(token, ekey)}}
						/>
					</div>
					<div className='checkbox-container'>
						<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required/>
						<label class="form-check-label" for="flexCheckDefault">{t('I agree to the ')} <Link href='/privacy-policy'>{t('Privacy Policy')}</Link></label>
					</div>
					<div className='button-container'>
						<button type="submit" disabled={this.state.disabledButton}>{content.Button}</button>
					</div>
				</div>
			</form>
		);
	}
}

export default contact;
