import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { useTranslation } from 'react-i18next';

// import style from './style.css';

class NotFound extends Component {

	render() {
		const {props} = this;
		const content = props.object
		const { t } = useTranslation();

		return (
			<div className='success-container'>
				<p className='title'>{content.Main}</p>
				<p className='desc'>{content.Description}</p>
				<div className='success-buttons'>
					<a href='/' type="button" class="btn btn-primary button-login">{t('Homepage')}</a>
				</div>
			</div>
		);
	}
}

export default NotFound;
