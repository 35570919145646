import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { useTranslation } from 'react-i18next';
// import style from './style.css';

class DarkHeader extends Component {

	render() {
		const {props} = this;
		const content = props.object
		const { t } = useTranslation();
		return (
            <div className='container-fluid'>
				<div className='login-header'>
					<div className='header-logo'>
						<Link href='/'>
						<picture>
							<source srcset="/assets/images/login-logo.webp" type="image/webp"></source>
							<source srcset="/assets/images/login-logo.png" type="image/png"></source>
							<img alt='dark_logo' src='/assets/images/login-logo.png'></img>
						</picture>
							
						</Link>
					</div>
					<div className='header-list'>
						<Link href='/contact' className='header-link'>{t('Contact')}</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default DarkHeader;
