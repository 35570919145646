import { Component, Fragment, h } from 'preact';
import { Link } from 'preact-router/match';
import { useContext } from 'preact/hooks';
import { ApiProviderContext } from '../ApiProvider';
import { LanguageSwitcher } from '../Language';
// import style from './style.css';

class Header extends Component {

	render() {
		const { content } = useContext(ApiProviderContext)

		return (
			<div className='tw-header-bg'>
				<div className='tw-header'>
				<div className='container-fluid'>
				<nav class="navbar navbar-expand-lg navbar-light bg-light">
					<Link className='navbar-brand' href='/'>
						<picture>
							<source srcset="/assets/images/logo.webp" type="image/webp"></source>
							<source srcset="/assets/images/logo.png" type="image/png"></source>
							<img src="/assets/images/logo.png" alt="logo"></img>
						</picture>
					</Link>
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-toggle="collapse" data-bs-target="#navbarSupportedContent" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav mr-auto">
						{content.data.attributes.menu.MenuItem.map((object, i) => {
							try {
								return (
									<li className='nav-item'>
										<Link className='nav-link' href={object.page.url}>{object.Title}</Link>
									</li>
								)
							} catch (error) {
								return null
							}
						})}
						<li>
							<LanguageSwitcher url={this.props?.url}></LanguageSwitcher>
						</li>
						</ul>
					</div>
					</nav>
				</div>
				</div>
			</div>
		);
	}
}

export default Header;
