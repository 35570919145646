import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { BACKEND_DOMAIN } from '../../../config';
import { useTranslation } from 'react-i18next';

// import style from './style.css';

class OurProducts extends Component {

	render() {
		const {props} = this;
		const content = props.object
		const { t } = useTranslation();

		return (
            <div className='products'>
				<div className='products-bg'>
					<div className='container'>
						<div className='products-title'>
							<p className='main-title'>{content.Title}</p>
							<Link>{content.More}</Link>
						</div>
						{(content?.ourproducts_products) ? (this.renderProducts(content)) : null}
					</div>
				</div>
			</div>
		);
	}

	renderProducts(content) {
		const { t } = useTranslation();

		return <div className='products-row'>
			{content?.ourproducts_products.map((item) => {
				return  <div className='products-item'>
					<div className='item-bg'>
						<p className='title'>{item.Title}</p>
						<p className='version'>{item.system}</p>
					</div>
					<div className='item-photo'>
						<img alt='product' src={BACKEND_DOMAIN + item?.image?.url}></img>
					</div>
					<div className='item-button'>
						<a target='_blank' href={item.Link} type="button" className="btn btn-primary">{t('Check')}</a>
					</div>
				</div>
			})}
		</div>
	}
}

export default OurProducts;
