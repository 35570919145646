import axios from 'axios';
import { Component, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import Header from './apiComponents/header';
import LoginForm from './apiComponents/loginForm';
import OurProducts from './apiComponents/ourProducts';
import AdditionalServices from './apiComponents/AdditionalServices';
import Footer from './apiComponents/Footer';
import Packages from './apiComponents/packages';
import RegisterForm from './apiComponents/registerForm';
import Success from './apiComponents/SuccessRegister';
import Panel from './apiComponents/panel';
import NotFound from './apiComponents/NotFound';
import DarkHeader from './apiComponents/DarkHeader';
import DarkFooter from './apiComponents/DarkFooter';
import Cms from './apiComponents/cms';
import Contact from './apiComponents/contact';
import EmailConfirmation from './apiComponents/emailConfirmation.js';
import { ApiProviderContext } from './ApiProvider';
import ContactBanner from './apiComponents/contactBanner';


const Loading = () =>
  <div className='loading-container'>
    <div className="loading">
      <div></div>
      <div></div>
    </div>
  </div>

class Page extends Component {
  // componentDidMount() {
  //   this.isLoading = setTimeout(() => { this.setState({ loading: false }) }, 2300);
  // }

  constructor() {
    super();
  }

  state = {
    loading: true,
  };

  componentWillUnmount() {
    clearTimeout(this.isLoading);
  }
  
  timer = () => setTimeout(() => {
    this.setState({ loading: false })
  }, 100);

  handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData()
    e.target.querySelectorAll('[name]').forEach((inp) => {
      console.log(inp.value)
      formData.append(inp.attributes.name.value, inp.value)
    })

    axios({
      method: "post",
      url: "myurl",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  render() {
    const { content } = useContext(ApiProviderContext)
    const { loading } = this.state;

    // console.log(apiProvider.state)
    // console.log(content.data.attributes.page.Title)
    if (content.data) {

      if (!content.data.attributes || !content.data.attributes.page) {
        return ('error :(');
      }

      let page = content.data.attributes.page;
      let props = this.props;

      return (
        <Fragment>

          <div className={`page_wrapper ` + page?.style ? page.style : 'white_style'}>
            {(page.Content) ? page.Content.map(function (object, i) {

              switch (object.__component) {
                case 'page-content.header':
                  return <Header page={page} object={object} url={props.url}></Header>

                case 'page-content.dark-header':
                  return <DarkHeader page={page} object={object} url={props.url}></DarkHeader>

                case 'page-content.dark-footer':
                  return <DarkFooter page={page} object={object} url={props.url}></DarkFooter>

                case 'page-content.login-form':
                  return <LoginForm page={page} object={object} url={props.url}></LoginForm>

                case 'page-content.register-form':
                  return <RegisterForm page={page} object={object} url={props.url}></RegisterForm>

                case 'page-content.packages':
                  return <Packages page={page} object={object} url={props.url}></Packages>

                case 'page-content.our-products':
                  return <OurProducts page={page} object={object} url={props.url}></OurProducts>

                case 'page-content.additional-services':
                  return <AdditionalServices page={page} object={object} url={props.url}></AdditionalServices>

                case 'page-content.footer':
                  return <Footer page={page} object={object} url={props.url}></Footer>

                case 'page-content.success-register-form':
                  return <Success page={page} object={object} url={props.url}></Success>

                case 'page-content.panel':
                  return <Panel page={page} object={object} url={props.url}></Panel>

                case 'page-content.404':
                  return <NotFound page={page} object={object} url={props.url}></NotFound>

                case 'page-content.cms':
                  return <Cms page={page} object={object} url={props.url}></Cms>

                case 'page-content.contact':
                  return <Contact page={page} object={object} url={props.url}></Contact>

                case 'page-content.email-confirmation':
                  return <EmailConfirmation page={page} object={object} url={props.url}></EmailConfirmation>

                case 'page-content.contact-banner':
                  return <ContactBanner page={page} object={object} url={props.url}></ContactBanner>

                default:
                  break;
              }

              return null;
            }) : null}
          </div>

        </Fragment>
      );
    } else {
      return <Loading />
    }

  }
}

export default Page;
