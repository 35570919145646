import { Component, Fragment } from 'preact';
import { useTranslation } from 'react-i18next';

class FatalError extends Component {
    constructor() {
        super();
    }

    state = {
        loading: true,
    };

    render() {
        const { t } = useTranslation();
        return (
            <Fragment>
                <div className='fatal'>
                    <p className='title'>{t('Website under maintance')}</p>
                    <p className='desc'>{t('Come back later')}</p>
                </div>
            </Fragment>
        );
    }
}

export default FatalError;
