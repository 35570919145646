import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { register } from '../../api';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { HCAPTCHA_SITE_KEY } from '../../../config';
import { useTranslation } from 'react-i18next';


// import style from './style.css';

class RegisterForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			username: '',
			system: '',
			hcaptcha_token: '',
			hcaptcha_ekey: '',
			error: '',
			errors: [],
			isSubmited: false,

			avaliable_systems: [
				'prestashop 1.7',
				'opencart',
				// 'magento 2',
				'woocommerce',
				'shopware 6',
				// 'Thirty Bees',
			],
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleVerificationSuccess(token, ekey) {
		this.setState({
			hcaptcha_token: token,
			hcaptcha_ekey: ekey,
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({ 'isSubmited': true })
		let returnData = await register(
			this.state.username,
			this.state.system,
			this.state.hcaptcha_token,
			this.state.hcaptcha_ekey,
		);

		if (returnData?.error) {
			this.setState({
				'error': returnData.error,
				'errors': returnData.errors,
			})
			this.setState({ 'isSubmited': false })
		}
	}

	render() {
		const { props } = this;
		const content = props.object
		const { t } = useTranslation();
		if (this.state.isSubmited) {
			return (
				<div className='relative-container'>
					<div className='loading-container'>
						<div className="loading">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div className='login-block'>
				<div className='block-bg'>
					<p className='title'>{content.Title}</p>
					<p className='desc'>{content.Description}</p>
					{this._renderForm()}
				</div>
			</div>
		);
	}

	_renderForm() {

		const { props } = this;
		const content = props.object
		const { t } = useTranslation();
		

		return (
			<form onSubmit={this.handleSubmit}>

				{(this.state.errors && this.state.errors.length ? (
					(this.state.errors.map((item) => {
						return <div class="alert alert-warning" role="alert">
							{item.message}
						</div>
					}))
				) : null)}

				<div class="form-container">
					<div class="inputs-container">
						<label for="uname"><b>{content.Username}</b></label>
						<input name="username" type="email" value={this.state.username} onInput={this.handleChange} required />

						<label for="uname"><b>{content.System}</b></label>
						<select name="system" value={this.state.system} onInput={this.handleChange} required >
							<option value="">{content.Dropdown1}</option>
							{this.state.avaliable_systems.map((value, index) => <option value={value} >{value}</option>)}
						</select>
					</div>
					<div class="inputs-container captcha-container">
						<HCaptcha
							sitekey={HCAPTCHA_SITE_KEY}
							onVerify={(token, ekey) => { this.handleVerificationSuccess(token, ekey) }}
						/>
					</div>
					<div className='checkbox-container'>
						<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
						<label class="form-check-label" for="flexCheckDefault">
							{t('I agree to the ')}<Link href='/privacy-policy' >{t('Privacy Policy')}</Link>
						</label>
					</div>
					<div className='button-container'>
						{/* <button type="submit">{content.Button}</button> */}
						<button type="submit" disabled={this.state.disabledButton}>{content.Button}</button>
					</div>
				</div>
			</form>
		);
	}
}

export default RegisterForm;
