import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { login } from '../../api';
// import style from './style.css';

class loginForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			error: '',
			errors: [],
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		let returnData = await login(this.state.username, this.state.password);

		if (returnData?.error) {
			this.state.error = returnData.error;
			this.state.errors = returnData.errors;
		}
	}

	render() {
		const {props} = this;
		const content = props.object
		return (
			<div className='login-block'>
				<div className='block-bg'>
					<p className='title'>{content.Title}</p>
					{this._renderForm()}
				</div>
			</div>
		);
	}

	_renderForm() {
		const {props, state} = this;
		const content = props.object
		return (
			<form onSubmit={this.handleSubmit}>
				<div class="form-container">

					{(state.error ? (
						<div class="alert alert-warning" role="alert">
							{state.error}
						</div>
					) : null)}


					<div className='inputs-container'>
					
						<label for="uname"><b>{content.Username}</b></label>
						<input name="username" type="text" placeholder={"Enter " + content.Username} value={this.state.username} onInput={this.handleChange} required />

						<label for="psw"><b>{content.Password}</b></label>
						<input name="password" type="password" placeholder={"Enter " + content.Password} value={this.state.password} onInput={this.handleChange} required />
					</div>
					<div className='button-container'>
						<button type="submit">{content.Button}</button>
					</div>
			
					</div>

					{/* <div class="mb-3">
						<label for="exampleFormControlInput1" class="form-label">Email address</label>
						<input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
					</div>
					<div class="mb-3">
						<label for="exampleFormControlTextarea1" class="form-label">Example textarea</label>
						<textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
					</div> */}
			</form>
		);
	}
}

export default loginForm;
