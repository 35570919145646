import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
// import style from './style.css';

class AdditionalServices extends Component {

	render() {
		const {props} = this;
		const content = props.object
		return (
            <div className='tw-services'>
				<div className='services'>
					<div className='container'>
						<div className='services-title'>
							<h2>{content.Title}</h2>
							<p>{content.Description}</p>
						</div>
						<div className='mobile-img'>
						<picture>
							<source srcset="/assets/images/services_mobile.webp" type="image/webp"></source>
							<source srcset="/assets/images/services_mobile.png" type="image/png"></source>
							<img alt='mobile_logo' src='assets/images/services_mobile.png'></img>
						</picture>
						</div>
						<div className='services-blocks'>
							<div className='blocks-container'>
								<div className='blocks-row'>
									<div className='blocks-item'>
										<p className='title'>{content.BlockOneTitle}</p>
										<p className='desc'>{content.BlockOneDescription}</p>
										<div>
											<a href='/contact' type="button" class="btn btn-primary">{content.Contact}</a>
										</div>
									</div>
									<div className='blocks-item'>
										<p className='title'>{content.BlockTwoTitle}</p>
										<p className='desc'>{content.BlockTwoDescription}</p>
										<div>
											<a href='/contact' type="button" class="btn btn-primary">{content.Contact}</a>
										</div>
									</div>
									<div className='blocks-item blocks-item-contact'>
										<p className='title'>{content.BlockThreeTitle}</p>
										<div>
											<a href='/contact' type="button" class="btn btn-primary">{content.Contact}</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AdditionalServices;
