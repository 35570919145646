import { Component, createContext, Fragment, h } from 'preact';
import { getPage } from '../api';
import { route } from 'preact-router';
import ReactGA from 'react-ga4';
import { GOOGLE_ANALITICS_TAG } from '../../config.local';


export const ApiProviderContext = createContext()


class ApiProvider extends Component {

    constructor() {
        super();
        this.state = {
            time: Date.now(),
            content: {}
        };

        //google analitics
        ReactGA.initialize(GOOGLE_ANALITICS_TAG);
    }

    // Lifecycle: Called whenever our component is created
    componentDidMount() {
        // update time every second
        // console.log('componentDidMount')
        this.timer = setInterval(() => {
            this.setState({ time: Date.now() });
        }, 1000);
    }

    // Lifecycle: Called just before our component will be destroyed
    componentWillUnmount() {
        // stop when not renderable
        clearInterval(this.timer);
        // console.log('unmount')
    }

    async routeDidUpdate(route) {
        if (route.url == '/fatal-error') {
            return;
        }
        if (route.url == '/error') {
            //podmieniamy route na index bo potrzebujemy danych
            route.url = '/';
            // return;
        }

        // let page = await getPage(route.url)

        // if (page.status == 404) {
        //     history.pushState(null, null, '/404');
        //     page = await getPage('/404')
        // }

        // this.setState({'content': page.response})

        return this.refresh(route.url)
    }

    async refresh(url) {
        this.setState({ 'content': {} })
        let page = await getPage(url)
        
        if (page?.response?.data?.attributes?.page?.Title) {
            // useEffect(() => {
            document.title = 'Teamwant - ' + page?.response?.data?.attributes?.page?.Title;
            // });
        }

        ReactGA.send({ hitType: "pageview", page: url });

        ReactGA.event(this.state);

        if (page.status >= 500) {
            route('/fatal-error', true);
            return;
        } else if (page.status == 404) {
            route('/404', true);
            return;
        } else if (page.status == 403 && page?.response?.error?.details?.redirect) {
            route(page.response.error.details.redirect, true);
            return;
        } else if (typeof page.response !== 'undefined' && typeof page.response.error !== 'undefined') {
            route('/error', true);
            return;
        }

        this.setState({ 'content': page.response })
    }

    getContent() {
        return this.state.content;
    }

    render() {

        let time = new Date(this.state.time).toLocaleTimeString();
        let apiProvider = this

        return (
            <ApiProviderContext.Provider
                value={{
                    apiProvider,
                    content: this.state.content
                }}
            >
                {/* <span>{this.props.children}{time}</span> */}
                {this.props.children}
            </ApiProviderContext.Provider>
        );
    }
}

export default ApiProvider;
