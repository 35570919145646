import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { useTranslation } from 'react-i18next';
// import style from './style.css';

class Footer extends Component {

	render() {
		const {props} = this;
		const content = props.object;
        const { t } = useTranslation();
		if(content.style === 'white'){
			return (
				<div className={'tw-footer ' + content.style}>
					<div className='footer-bg'>
						<div className='footer-row'>
							<div className='footer-box'>
								<ul className='footer-navigation'>
									<li><Link href='/'>{t('Home')}</Link></li>
									<li><Link href='/contact'>{t('Contact')}</Link></li>
									<li><Link href='/privacy-policy'>{t('Privacy Policy')}</Link></li>
								</ul>
							</div>
							<div className='footer-box'>
								<hr></hr>
							</div>
							<div className='footer-box'>
								<p>Copyright &copy; 2022 TEAMWANT | All Rights Reserved.</p>
							</div>
						</div>
						<div className='footer-row'>
							<div className='footer-box'></div>
						</div>
					</div>
				</div>
			)
		}
		return (			
            <div className={'tw-footer ' + content.style}>
				<div className='footer-bg'>
					<div className='container-fluid'>
						<div className='footer-row'>
							<div className='footer-box'>
								<p>Copyright &copy; 2022 TEAMWANT | {t('All Rights Reserved')}.</p>
								<ul className='footer-terms'>
									<li><Link href='/privacy-policy'>{t('Privacy Policy')}</Link></li>
								</ul>
							</div>
							<div className='footer-box'>
								<ul className='footer-navigation'>
									<li><Link href='/'>{t('Home')}</Link></li>
									<li>/</li>
									<li><Link href='/contact'>{t('Contact')}</Link></li>
								</ul>
							</div>
						</div>
						<div className='footer-row'>
							<div className='footer-box other'>
								<p><a href="https://www.vecteezy.com/free-vector/hosting">Hosting Vectors by Vecteezy</a></p>
								<p>shopping cart PNG Designed By Jahanullah from <a href="https://pngtree.com"> Pngtree.com</a></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Footer;
