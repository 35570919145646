import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import ReactMarkdown from 'react-markdown'

// import style from './style.css';

class Packages extends Component {
	render() {
		const {props} = this;
		const content = props.object
		return (
			
            <div className='tw-packages'>
				<div className='packages-bg'>
					<div class="container">
						<div class="packages-title">
							<p className='title'>{content.Title}</p>
							<p className='desc'>{content.Description}</p>
						</div>
						<div class="packages-row">
							<div class="packages-item">
								<div class="item-single packages-free">
									<p className='above'>{content.FreeAbove}</p>
									<p className='main'>{content.FreeMain}</p>
									<div>
										<ReactMarkdown children={content.FreeDescription} />
									</div>
									<Link href='/new-site' class="btn btn-primary">{content.FreeButton}</Link>
								</div>
							</div>
							<div class="packages-item">
								<div class="item-single packages-premium">
									<p className='above'>{content.PremiumAbove}</p>
									<p className='main'>{content.PremiumMain}</p>
									<div>
										<ReactMarkdown children={content.PremiumDescription} />
									</div>
									<Link href='/contact' type="button" class="btn btn-primary">{content.PremiumButton}</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Packages;
